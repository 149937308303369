<template>
  <div class="login">

    <div class="public-screen middle-box text-center animated fadeIn">
      <img class="logo" src="@/assets/images/prologistics_logo.png" alt="Logo"/>

      <div>
        <b-form @submit.prevent="onSubmit">

          <generic-input
              v-model="form.email"
              type="email"
              :placeholder="$t('login.email')"
              required
          ></generic-input>

          <generic-input
              v-model="form.password"
              :placeholder="$t('login.password')"
              type="password"
              required
          ></generic-input>

          <b-form-checkbox v-model="form.remember_me" class="m-b-sm">{{ $t('login.remember_me') }}</b-form-checkbox>

          <b-button type="submit" variant="primary">{{
              $t('button.login')
            }}
          </b-button>
        </b-form>

      </div>

      <router-link :to="{name: 'registration'}">
        <b-button class="btn-outline m-t" variant="primary">{{ $t('button.registration') }}</b-button>
      </router-link>

      <shared-links class="m-t" :showPasswordResetButton="true"/>
    </div>

  </div>
</template>

<script>
import SharedLinks from "@/components/shared_links";
import {login} from "@/services/employees";
import {mapActions} from "vuex"
import GenericInput from "@/components/generic_input.vue";
import Cookies from "js-cookie";

export default {
  components: {GenericInput, SharedLinks},
  data() {
    return {
      form: {
        email: '',
        password: '',
        remember_me: false,
      }
    }
  },
  methods: {
    ...mapActions("user", ["setUser"]),
    onSubmit(event) {
      login(this.form).then(response => {
        this.setUser(response)
        // login to cookie redirect if present
        const redirect = Cookies.get("redirect")
        Cookies.remove("redirect")
        if (typeof redirect === 'string') {
          return this.$router.push(redirect)
        }
        this.$router.push({name: 'dashboard'})
      }).catch()
    },
  },
  computed: {

  }
}
</script>

<style scoped lang="scss">
.logo {
  width: 15em;
  margin-top: 40px;
  margin-bottom: 40px;
}

.btn {
  width: 80%;
}
</style>
